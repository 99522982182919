import { Card, Text } from "@chakra-ui/react";
import Action from "../../Models/Action.ts";
import ServiceAny from "../../Components/Services/ServiceAny.tsx";
import ServiceDiscord from "../../Components/Services/ServiceDiscord.tsx";
import ServiceGoogle from "../../Components/Services/ServiceGoogle.tsx";
import {
  FaCloudSun,
  FaGithub,
  FaMicrosoft,
  FaSpotify,
  FaTwitter,
} from "react-icons/fa";

const iconProps = {
  width: "35px",
  height: "35px",
  marginRight: "10px",
};

interface ActionCardProps {
  action: Action;
  onClick: (id: string) => void;
}

function ActionCard({ action, onClick }: ActionCardProps) {
  return (
    <Card
      onClick={() => onClick(action.id)}
      style={{
        padding: "20px",
        backgroundColor: "#FFFFFF",
        maxWidth: "500px",
        borderRadius: "10px",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {action.service_name === "Google" && <ServiceGoogle />}
      {action.service_name === "Discord" && <ServiceDiscord />}
      {action.service_name === "Weather" && <FaCloudSun style={iconProps} />}
      {action.service_name === "Spotify" && <FaSpotify style={iconProps} />}
      {action.service_name === "Twitter" && <FaTwitter style={iconProps} />}
      {action.service_name === "Microsoft" && <FaMicrosoft style={iconProps} />}
      {action.service_name === "Github" && <FaGithub style={iconProps} />}
      {!action.service_name && <ServiceAny />}
      <Text>{action.name}</Text>
    </Card>
  );
}

export default ActionCard;
