import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

const IButton = ({
  icon,
  children,
  onClick,
  ref,
  width,
  minW,
  color,
  backgroundColor,
  textColor,
  ...props
}: {
  icon?: IconDefinition;
  children?: ReactNode;
  onClick?: () => void;
  width?: string;
  minW?: string;
  color?: string;
  backgroundColor?: string;
  textColor?: string;
  ref?: any;
}) => {
  return (
    <Button
      {...props}
      w={width}
      minW={minW || "200px"}
      maxW="235px"
      color={color || "#EEEDED"}
      backgroundColor={backgroundColor || "#EEEDED"}
      borderRadius={"100px"}
      onClick={onClick}
      ref={ref}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <HStack spacing={2} h="45px">
        {icon && (
          <Icon>
            <FontAwesomeIcon color={textColor || "black"} icon={icon} />
          </Icon>
        )}
        {children && (
          <Text fontWeight={"light"} color={textColor || "black"}>
            {children}
          </Text>
        )}
      </HStack>
    </Button>
  );
};

export default IButton;
