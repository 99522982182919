import {styled} from "@chakra-ui/react";
import {CSSProperties, ReactNode} from "react";

const CardContent = styled("div", {
  baseStyle: {
    backgroundColor: "white",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }
})

interface CardProps {
  children: ReactNode;
  style?: CSSProperties;
}

function Card({
                children,
                style,
              }: CardProps) {

  return (
    <CardContent style={style}>
      {children}
    </CardContent>
  )
}

export default Card;