import PageTemplate from "../Components/Global/PageTemplate";
import Card from "../Components/Global/Card/Card";
import Logo from "../assets/area.svg";
import InputIcon from "../Components/Global/Input/InputIcon";
import {SetStateAction, useState} from "react";
import {GoMail} from "react-icons/go";
import {useTranslation} from "react-i18next";
import {MdPassword} from "react-icons/md";
import {useAuthContext} from "../Context/AuthContext";
import {Button, Stack, styled, Text} from "@chakra-ui/react";
import {IoArrowBack} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import {FcGoogle} from "react-icons/fc";
import {URL_Server} from "../Services/Api.ts";
import {BsMicrosoft} from "react-icons/bs";

const LoginContent = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
});

const LoginInputs = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "70%",
  },
});

const SwitchContent = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});

const TextSwitch = styled("p", {
  baseStyle: {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: "400",
    color: "#000000",
    marginTop: "10px",
  },
});

const TextSwitchLink = styled("p", {
  baseStyle: {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: "400",
    color: "#FD8147",
    marginTop: "10px",
    textDecoration: "underline",
    marginLeft: "5px",
    cursor: "pointer",
  },
});

const PageTitle = styled("p", {
  baseStyle: {
    fontFamily: "DM Sans",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#000000",
    marginTop: "10px",
  },
});

function Login() {
  const navigate = useNavigate();
  const {setAuthenticationMode, handleLogin} = useAuthContext();

  const {t} = useTranslation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <PageTemplate>

      <Stack position={"absolute"} top={25} left={25} onClick={() => navigate("/")} cursor={"pointer"}
             direction={"row"} alignItems={"center"}
      >
        <IoArrowBack size={24}/>
        <Text fontSize={"14px"} fontWeight={"bold"} fontFamily={"DM Sans"}>{t("labels.back")}</Text>
      </Stack>

      <LoginContent>
        <Card
          style={{
            width: "400px",
            paddingTop: "30px",
            paddingBottom: "50px",
          }}
        >
          <img src={Logo} alt="Logo" style={{width: "90px"}}/>

          <PageTitle>{t("pages.login.title")}</PageTitle>

          <LoginInputs>
            <InputIcon
              style={{fontFamily: "DM Sans"}}
              icon={<GoMail/>}
              placeholder={t("labels.email")}
              value={username}
              onChange={(e: { target: { value: SetStateAction<string> } }) =>
                setUsername(e.target.value)
              }
              type={"text"}
              isRequired={true}
              isInvalid={false}
            />

            <InputIcon
              style={{fontFamily: "DM Sans"}}
              icon={<MdPassword/>}
              placeholder={t("labels.password")}
              value={password}
              onChange={(e: { target: { value: SetStateAction<string> } }) =>
                setPassword(e.target.value)
              }
              type={"password"}
              isRequired={true}
              isInvalid={false}
            />
          </LoginInputs>
          <Button
            variant="solid"
            backgroundColor={"#EEEDED"}
            _hover={{backgroundColor: "#dcdcdc"}}
            marginTop={"20px"}
            borderRadius={"50px"}
            fontWeight={"regular"}
            fontFamily={"DM Sans"}
            width={"45%"}
            onClick={() => {
              handleLogin(username, password);
            }}
          >
            {t("labels.login")}
          </Button>

          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} marginTop={"20px"}
                 padding={"10px"} cursor={"pointer"} borderRadius={4} boxShadow={"0px 0px 3px rgba(0,0,0,0.25)"}
                 onClick={() => {
                   window.location.href = `${URL_Server}/auth/google`;
                 }}
                 width={"60%"}
          >
            <FcGoogle size={24}/>
            <Text fontSize={"14px"} fontWeight={"bold"} fontFamily={"DM Sans"} textColor={"#707070"}
                  marginLeft={"5px"}>{t("pages.login.google")}</Text>
          </Stack>

          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} marginTop={"20px"}
                 padding={"10px"} cursor={"pointer"} borderRadius={4} boxShadow={"0px 0px 3px rgba(0,0,0,0.25)"}
                  onClick={() => {
                    window.location.href = `${URL_Server}/auth/microsoft`;
                  }}
                 width={"65%"} backgroundColor={"#195bbe"}
          >
            <BsMicrosoft size={24} color={"#ffffff"}/>
            <Text fontSize={"14px"} fontWeight={"bold"} fontFamily={"DM Sans"} textColor={"#ffffff"}
                  marginLeft={"5px"}>{t("pages.login.microsoft")}</Text>
          </Stack>

        </Card>
        <SwitchContent>
          <TextSwitch>{t("pages.login.switch")}</TextSwitch>
          <TextSwitchLink
            onClick={() => {
              setAuthenticationMode("register");
            }}
          >
            {t("pages.login.switchLink")}
          </TextSwitchLink>
        </SwitchContent>
      </LoginContent>
    </PageTemplate>
  );
}

export default Login;
