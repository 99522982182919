import Api from "./Api";

export default class WorkflowServices {
  static createWorkflow = ({
    name,
    emoji,
    description,
    data,
    condition,
    action_id,
    reaction_ids,
    reactions_delay_ms,
  }: {
    name: string;
    emoji: string;
    description: string;
    data: string;
    condition: string;
    action_id?: string | null;
    reaction_ids?: Array<string>;
    reactions_delay_ms?: number;
  }) => {
    return Api.post(`workflows/`, {
      name,
      emoji,
      description,
      action_id,
      reaction_ids,
      reactions_delay_ms,
      data,
      condition,
    });
  };

  static updateWorkflow = ({
    id,
    name,
    description,
    data,
    condition,
    action_id,
    reaction_ids,
    reactions_delay_ms,
    is_active,
    emoji,
  }: {
    id: string;
    name?: string;
    description?: string;
    data?: string;
    condition?: string;
    action_id?: string;
    reaction_ids?: Array<string>;
    reactions_delay_ms?: number;
    is_active?: boolean;
    emoji?: string;
  }) => {
    return Api.patch(`workflows/${id}`, {
      name,
      description,
      data,
      condition,
      action_id,
      reaction_ids,
      reactions_delay_ms,
      is_active,
      emoji,
    });
  };

  static getWorkflows = () => {
    return Api.get(`workflows/`);
  };

  static getLogs = () => {
    return Api.get(`logs/`);
  };

  static getWorkflow = (id: string) => {
    return Api.get(`workflows/${id}`);
  };

  static deleteWorkflow = (id: string) => {
    return Api.delete(`workflows/${id}`);
  };
}
