import {Stack, Text} from "@chakra-ui/react";
import ContactImage from "../../assets/contact.svg";
import {forwardRef} from "react";

const Contact = forwardRef((_props, ref) => {
  return (
    <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} h={"100%"}
      /* @ts-ignore */
           w={"100%"} marginTop="100px" spacing={100} ref={ref}
    >

      <img src={ContactImage} alt={"Contact"} width={500}/>

      <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} h={"100%"}>
        <Text fontSize={"6xl"} fontWeight={"bold"}>Contact</Text>
        <Text fontSize={"xl"}>Coming Soon!</Text>
        <Text fontSize={"xl"}>We are looking for a secretary 😉</Text>
      </Stack>
    </Stack>
  )
})

export default Contact;