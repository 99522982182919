import {Stack, Text} from "@chakra-ui/react";
import Area from "../../assets/area.svg";

function Footer() {

  return (
    <Stack w={"100vw"} backgroundColor={"#222222"} alignItems={"end"} justifyContent={"space-evenly"}
           marginTop={"150px"} direction={"row"} width={"100%"} paddingBottom={"30px"}>

      <Stack direction={"column"} h={"100%"} marginTop={"40px"} marginBottom={"40px"}>
        <img src={Area} alt={"area"} height={50} width={75}/>

        <Text fontSize={30} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>AREA 51</Text>

        <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Automate your life</Text>

      </Stack>

      <Stack direction={"column"} alignItems={"center"} h={"100%"} marginTop={"40px"} marginBottom={"40px"}>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Home</Text>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Features</Text>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Pricing</Text>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Contact</Text>

      </Stack>

      <Stack direction={"column"} alignItems={"center"} h={"100%"} marginTop={"40px"} marginBottom={"40px"}>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Login</Text>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Sign up</Text>

      </Stack>

      <Stack direction={"column"} alignItems={"center"} h={"100%"} marginTop={"40px"} marginBottom={"40px"}>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>English</Text>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Français</Text>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Español</Text>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Deutsch</Text>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Italiano</Text>

      </Stack>

      <Stack direction={"column"} alignItems={"center"} h={"100%"} marginTop={"40px"} marginBottom={"40px"}>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>About us</Text>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Terms of service</Text>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Privacy policy</Text>

      </Stack>

      <Stack direction={"column"} alignItems={"center"} h={"100%"} marginTop={"40px"} marginBottom={"40px"}>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>© 2021 AREA 51</Text>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>All rights reserved</Text>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Made with ❤️ by</Text>

        <Text fontSize={14} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Epitech students</Text>

      </Stack>

    </Stack>
  )
}

export default Footer