import {Card, Stack, Text} from "@chakra-ui/react";

function Focus() {

  return (
    <Stack w={"100vw"} backgroundColor={"#222222"} alignItems={"center"} justifyContent={"center"} marginTop={"50px"}>
      <Stack direction={"row"} alignItems={"center"} h={"100%"} marginTop={"40px"} marginBottom={"40px"}>
        <Text fontSize={30} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Stay focused on</Text>

        <Card backgroundColor={"#FF9800"} textColor={"white"} paddingRight={3} paddingLeft={3} paddingTop={1}
              paddingBottom={1}>
          <Text fontSize={30} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>your goals</Text>
        </Card>
        <Text fontSize={30} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>with AREA 51</Text>

        <Text fontSize={45} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>.</Text>

      </Stack>

    </Stack>
  )
}

export default Focus
