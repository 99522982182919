import {Card, Stack, Text} from "@chakra-ui/react";
import FreeTier from "../Landing/Pricing/FreeTier.tsx";
import PremiumPlan from "../Landing/Pricing/PremiumPlan.tsx";
import {useEffect, useState} from "react";
import User from "../../Models/User.ts";
import UserServices from "../../Services/User.ts";
import {UserResponse} from "../../Views/Profile/Profile.tsx";
import {getStripeToBasic, getStripeToPremium} from "../../Utils/OAuth.ts";

function SubscribePricing() {

  const [user, setUser] = useState<User>();

  useEffect(() => {
    UserServices.getUser().then((res: UserResponse) => {
      setUser({
        ...res.user,
        connections: res.connections as {
          google: string;
          github: string;
          spotify: string;
          microsoft: string;
          twitter: string;
        }
      })
    });
  }, []);

  const handleGoToPremium = () => {
    window.location.href = getStripeToPremium();
  }

  const handleGoToBasic = () => {
    window.location.href = getStripeToBasic();
  }

  return (
    <Stack direction={"column"} overflowY={"scroll"} h={"100vh"} w={"100%"} alignItems={"center"}
           justifyContent={"center"}>
      <Stack>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} h={"100%"}>
          <Text fontSize={45} fontWeight={"bold"} fontFamily="DM Sans">Our</Text>

          <Card backgroundColor={"#222222"} textColor={"white"} paddingRight={3} paddingLeft={3} paddingTop={1}
                paddingBottom={1}>
            <Text fontSize={45} fontWeight={"bold"} fontFamily="DM Sans">Specialized pricing</Text>
          </Card>

          <Text fontSize={70} fontWeight={"bold"} fontFamily="DM Sans" textColor={"#FF9800"}>.</Text>

        </Stack>
        <Stack direction={"column"} alignItems={"center"} justifyContent={"space-between"}>
          <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">Switch easily between plans - Try it now for
            free</Text>
        </Stack>
      </Stack>

      <Stack direction={"row"} justifyContent={"center"} h={"100%"} marginTop={"30px"} flexWrap={"wrap"}>

        <Stack direction={"column"} alignItems={"center"}>
          <FreeTier/>
          <Stack direction={"column"} alignItems={"center"} width={"250px"} justifyContent={"center"}
                 backgroundColor={"#FF9800"} borderRadius={5} shadow={"xl"} height={"50px"}
          >
            <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"} cursor={user?.is_premium ? "pointer" : "not-allowed"}
                  onClick={() => {
                    if (user?.is_premium) {
                      handleGoToBasic();
                    }
                  }}
            >
              Select Free tier
            </Text>
          </Stack>
        </Stack>

        <Stack direction={"column"} alignItems={"center"}>
          <PremiumPlan/>
          <Stack direction={"column"} alignItems={"center"} width={"250px"} justifyContent={"center"}
                 backgroundColor={"#FF9800"} borderRadius={5} shadow={"xl"} height={"50px"} marginLeft={"50px"}
          >
            <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}
                  cursor={!user?.is_premium ? "pointer" : "not-allowed"}
                  onClick={() => {
                    if (!user?.is_premium) {
                      handleGoToPremium();
                    }
                  }}
            >
              Select Premium plan
            </Text>
          </Stack>
        </Stack>

      </Stack>

    </Stack>
  )
}

export default SubscribePricing
