import { Button, Divider, IconButton, Stack, Text } from "@chakra-ui/react";
import AreaCard from "../../Components/Global/AreaCard.tsx";
import Header from "../../Components/Global/Header.tsx";
import { useEffect, useState } from "react";
import WorkflowServices from "../../Services/WorkflowServices";
import Log from "../../Models/Log.ts";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function LogEntry({ message, created_at, workflow_id }: Log) {
  const navigate = useNavigate();

  return (
    <Stack color="#A9A9A9" direction="row">
      <Text>👻 {message}</Text>
      <Stack width="60%" direction={"column"}>
        <Text marginLeft="auto">{moment(created_at).fromNow()}...</Text>
        <Divider borderBottomColor="#D2D2D2" />
      </Stack>
      <IconButton
        color="#D2D2D2"
        background="none"
        aria-label=""
        icon={<FontAwesomeIcon icon={faBook} />}
        onClick={() => navigate(`/dashboard/workflows/${workflow_id}`)}
      />
    </Stack>
  );
}

function LastLogs() {
  const [logs, setLogs] = useState<Log[]>([]);

  useEffect(() => {
    WorkflowServices.getLogs().then((res) => {
      setLogs(res);
    });
  }, []);

  const { t } = useTranslation();

  return (
    <AreaCard>
      <Header alignEmoji emoji="🪰️">
        {t("pages.dashboard.lastLogs")}
      </Header>
      <Stack m={10}>
        {!logs.length ? (
          <Text color="gray.500">{t("pages.dashboard.thereAreNoLogs")}</Text>
        ) : (
          logs.map((log) => <LogEntry key={log.id} {...log} />)
        )}
      </Stack>
    </AreaCard>
  );
}

export default LastLogs;
