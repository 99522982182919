import {useAuthContext} from "../../Context/AuthContext.tsx";
import {
  Avatar,
  CircularProgress,
  Editable,
  EditableInput,
  EditablePreview, Modal, ModalContent, ModalOverlay,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import UserServices from "../../Services/User.ts";
import IButton from "../../Components/Global/IButton.tsx";
import {faPenToSquare, faPlus, faUnlock} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import User from "../../Models/User";
import AreaCard from "../../Components/Global/AreaCard";
import {GrLanguage} from "react-icons/gr";
import FrFlag from "../../assets/france.jpg";
import EnFlag from "../../assets/english.png";
import {FcGoogle} from "react-icons/fc";
import {getGithub, getGoogle, getMicrosoft, getSpotify, getTwitter} from "../../Utils/OAuth.ts";
import {BsMicrosoft, BsSpotify, BsTwitter} from "react-icons/bs";
import {FaGithub} from "react-icons/fa";

export interface UserResponse {
  user: User;
  connections: object;
}

function Profile() {
  const {handleLogout} = useAuthContext();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {isOpen, onOpen, onClose} = useDisclosure()

  const [user, setUser] = useState<User>();

  const [connections, setConnections] = useState<any[]>([]);

  useEffect(() => {
    let connections = []

    if (user?.connections?.google) {
      connections.push({
        name: "Google",
        email: user?.connections?.google
      })
    }

    if (user?.connections?.github) {
      connections.push({
        name: "Github",
        email: user?.connections?.github
      })
    }

    if (user?.connections?.spotify) {
      connections.push({
        name: "Spotify",
        email: user?.connections?.spotify
      })
    }

    if (user?.connections?.microsoft) {
      connections.push({
        name: "Microsoft",
        email: user?.connections?.microsoft
      })
    }

    if (user?.connections?.twitter) {
      connections.push({
        name: "Twitter",
        email: user?.connections?.twitter
      })
    }

    setConnections(connections)
  }, [user]);

  useEffect(() => {
    UserServices.getUser().then((res: UserResponse) => {
      setUser({
        ...res.user,
        connections: res.connections as {
          google: string;
          github: string;
          spotify: string;
          microsoft: string;
          twitter: string;
        }
      })
    });
  }, []);

  if (!user) return <CircularProgress m="auto"/>;

  const handleLogoutButton = () => {
    handleLogout();
    navigate("/");
  };

  const updateNickname = (nickname: string) => {
    UserServices.updateUser({nickname: nickname}).then((res) => {
      if (res) {
        setUser(res.user);
      }
    });
  }

  const switchLang = () => {
    if (localStorage.getItem("i18nextLng") === "FR") {
      localStorage.setItem("i18nextLng", "EN");
    } else {
      localStorage.setItem("i18nextLng", "FR");
    }
    window.location.reload();
  }

  return (
    <Stack
      w="100%"
      h="100%"
      flex={1}
    >
      <Stack
        direction={"row"}
        marginTop="10vh"
        marginLeft="5vw"
      >
        <Stack w={"80%"} direction={"row"}>
          <Avatar size={"xl"} name={user.nickname} borderRadius={5} fontFamily={"DM Sans"} userSelect="none"/>

          <Stack direction="column" alignItems="start" marginLeft="15px">
            <Editable defaultValue={user.nickname} fontFamily={"DM Sans"} fontSize={24} userSelect="none"
                      onChange={(value) => updateNickname(value)} fontWeight="bold">
              <EditableInput/>
              <EditablePreview/>
            </Editable>
            <Stack direction={"row"} alignItems="center">
              <Text fontFamily={"DM Sans"} fontSize={14} userSelect="none">📧</Text>
              <Editable defaultValue={user.email} fontFamily={"DM Sans"} fontSize={14} userSelect="none"
                        onChange={(value) => UserServices.updateUser({email: value})}>
                <EditableInput/>
                <EditablePreview/>
              </Editable>
            </Stack>
            <Stack direction={"row"} alignItems="center">
              <Text fontFamily={"DM Sans"} fontSize={14} userSelect="none">📍</Text>
              <Editable defaultValue={user.city || "Non définie"} fontFamily={"DM Sans"} fontSize={14} userSelect="none"
                        onChange={(value) => UserServices.updateUser({city: value})}>
                <EditableInput/>
                <EditablePreview/>
              </Editable>
            </Stack>
          </Stack>

          <Stack flexGrow={1}/>

          <Stack>

            {/* Switch lang */}
            <Stack direction="row" alignItems="center" justify="space-between" marginRight="20px">
              <GrLanguage size={24}/>

              {localStorage.getItem("i18nextLng") === "FR" ? (
                <img src={FrFlag} alt="fr" width={28} height={28} style={{borderRadius: "10%"}} onClick={
                  () => switchLang()}/>
              ) : (
                <img src={EnFlag} alt="fr" width={28} height={28} style={{borderRadius: "10%"}} onClick={
                  () => switchLang()}/>
              )}

            </Stack>
          </Stack>

        </Stack>

        <Stack alignItems="center" justify="space-between" marginRight="50px" marginBottom="50px" marginLeft="auto">
          <IButton backgroundColor="#EF5454FF" color="#EEEDED" textColor="#FFF" icon={faUnlock} minW={"150px"}
                   onClick={() => handleLogoutButton()}>
            {t("pages.profile.logout")}
          </IButton>
        </Stack>
      </Stack>

      <Stack direction={"column"} marginTop="20px" marginLeft="5vw" flexGrow={1} userSelect="none" overflow="auto">

        <Stack direction="row" alignItems="center" justify="space-between" w="80%" marginBottom={5}>

          <Text fontSize={24} fontWeight="500" fontFamily="DM Sans" userSelect="none">
            {t("pages.profile.connection")} ({connections.length})
          </Text>

          <Stack marginRight="5px">
            <IButton icon={faPlus} width={"120px"} minW={"40px"} onClick={() => onOpen()}>
              {t("labels.add")}
            </IButton>
          </Stack>
        </Stack>

        {user.connections?.google && (
          <>
            {/* @ts-ignore */}
            <AreaCard w="80%">
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={28} fontWeight="500" fontFamily="DM Sans" userSelect="none">📧</Text>
                  <Text fontSize={22} fontWeight="500" fontFamily="DM Sans" userSelect="none">Gmail</Text>
                </Stack>

                <Stack marginRight="5px">
                  <IButton icon={faPenToSquare} width={"40px"} minW={"40px"} onClick={() => navigate("/connect")}/>
                </Stack>
              </Stack>

              <Stack direction="row" gap={5} wrap="wrap" marginTop="10px">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">👤</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans"
                        userSelect="none">{user.connections.google}
                  </Text>
                </Stack>

              </Stack>

            </AreaCard>
          </>
        )}

        {user.connections?.github && (
          <>
            {/* @ts-ignore */}
            <AreaCard w="80%" marginTop="20px">
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={28} fontWeight="500" fontFamily="DM Sans" userSelect="none">🔐</Text>
                  <Text fontSize={22} fontWeight="500" fontFamily="DM Sans" userSelect="none">GitHub</Text>
                </Stack>

                <Stack marginRight="5px">
                  <IButton icon={faPenToSquare} width={"40px"} minW={"40px"} onClick={() => navigate("/connect")}/>
                </Stack>
              </Stack>

              <Stack direction="row" gap={5} wrap="wrap" marginTop="10px">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">👤</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans"
                        userSelect="none">
                    {user.connections.github}
                  </Text>
                </Stack>

              </Stack>

            </AreaCard>
          </>
        )}

        {user.connections?.spotify && (
          <>
            {/* @ts-ignore */}
            <AreaCard w="80%" marginTop="20px">
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={28} fontWeight="500" fontFamily="DM Sans" userSelect="none">🎵</Text>
                  <Text fontSize={22} fontWeight="500" fontFamily="DM Sans" userSelect="none">Spotify</Text>
                </Stack>

                <Stack marginRight="5px">
                  <IButton icon={faPenToSquare} width={"40px"} minW={"40px"} onClick={() => navigate("/connect")}/>
                </Stack>
              </Stack>

              <Stack direction="row" gap={5} wrap="wrap" marginTop="10px">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">👤</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans"
                        userSelect="none">
                    {user.connections.spotify}
                  </Text>
                </Stack>

              </Stack>

            </AreaCard>
          </>
        )}

        {user.connections?.microsoft && (
          <>
            {/* @ts-ignore */}
            <AreaCard w="80%" marginTop="20px">
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={28} fontWeight="500" fontFamily="DM Sans" userSelect="none">👤</Text>
                  <Text fontSize={22} fontWeight="500" fontFamily="DM Sans" userSelect="none">Microsoft</Text>
                </Stack>

                <Stack marginRight="5px">
                  <IButton icon={faPenToSquare} width={"40px"} minW={"40px"} onClick={() => navigate("/connect")}/>
                </Stack>
              </Stack>

              <Stack direction="row" gap={5} wrap="wrap" marginTop="10px">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">👤</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans"
                        userSelect="none">
                    {user.connections.microsoft}
                  </Text>
                </Stack>

              </Stack>

            </AreaCard>
          </>
        )}

        {user.connections?.twitter && (
          <>
            {/* @ts-ignore */}
            <AreaCard w="80%" marginTop="20px">
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={28} fontWeight="500" fontFamily="DM Sans" userSelect="none">🐦</Text>
                  <Text fontSize={22} fontWeight="500" fontFamily="DM Sans" userSelect="none">Twitter</Text>
                </Stack>

                <Stack marginRight="5px">
                  <IButton icon={faPenToSquare} width={"40px"} minW={"40px"} onClick={() => navigate("/connect")}/>
                </Stack>
              </Stack>

              <Stack direction="row" gap={5} wrap="wrap" marginTop="10px">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">👤</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans"
                        userSelect="none">
                    {user.connections.twitter}
                  </Text>
                </Stack>

              </Stack>

            </AreaCard>
          </>
        )}

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay/>
          <ModalContent flexDirection={"column"} alignItems={"center"} justifyContent={"center"} height={"500px"}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} marginTop={"20px"}
                   padding={"10px"} cursor={"pointer"} borderRadius={4} boxShadow={"0px 0px 3px rgba(0,0,0,0.25)"}
                   onClick={() => {
                     window.location.assign(getGoogle())
                   }}
                   width={"60%"} marginBottom={"20px"}
            >
              <FcGoogle size={24}/>
              <Text fontSize={"14px"} fontWeight={"bold"} fontFamily={"DM Sans"} textColor={"#707070"}
                    marginLeft={"5px"}>{t("pages.login.google")}</Text>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} marginTop={"20px"}
                   padding={"10px"} cursor={"pointer"} borderRadius={4} boxShadow={"0px 0px 3px rgba(0,0,0,0.25)"}
                   onClick={() => {
                     window.location.assign(getGithub())
                   }}
                   width={"60%"} marginBottom={"20px"}
            >
              <FaGithub size={24}/>
              <Text fontSize={"14px"} fontWeight={"bold"} fontFamily={"DM Sans"} textColor={"#707070"}
                    marginLeft={"5px"}>{t("pages.login.github")}</Text>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} marginTop={"20px"}
                   padding={"10px"} cursor={"pointer"} borderRadius={4} boxShadow={"0px 0px 3px rgba(0,0,0,0.25)"}
                   onClick={() => {
                     window.location.assign(getSpotify())
                   }}
                   width={"60%"} marginBottom={"20px"}
            >
              <BsSpotify size={24}/>
              <Text fontSize={"14px"} fontWeight={"bold"} fontFamily={"DM Sans"} textColor={"#707070"}
                    marginLeft={"5px"}>{t("pages.login.spotify")}</Text>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} marginTop={"20px"}
                   padding={"10px"} cursor={"pointer"} borderRadius={4} boxShadow={"0px 0px 3px rgba(0,0,0,0.25)"}
                   onClick={() => {
                     window.location.assign(getTwitter())
                   }}
                   width={"60%"} marginBottom={"20px"}
            >
              <BsTwitter size={24}/>
              <Text fontSize={"14px"} fontWeight={"bold"} fontFamily={"DM Sans"} textColor={"#707070"}
                    marginLeft={"5px"}>{t("pages.login.twitter")}</Text>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} marginTop={"20px"}
                   padding={"10px"} cursor={"pointer"} borderRadius={4} boxShadow={"0px 0px 3px rgba(0,0,0,0.25)"}
                   onClick={() => {
                     window.location.assign(getMicrosoft())
                   }}
                   width={"60%"} marginBottom={"20px"}
            >
              <BsMicrosoft size={24}/>
              <Text fontSize={"14px"} fontWeight={"bold"} fontFamily={"DM Sans"} textColor={"#707070"}
                    marginLeft={"5px"}>{t("pages.login.microsoft")}</Text>
            </Stack>
          </ModalContent>
        </Modal>

        <Text fontSize={24} fontWeight="500" fontFamily="DM Sans" userSelect="none" marginTop={50}>
          {t("pages.profile.subscription")} (1)
        </Text>

        {!user.is_premium && (
          <>
            {/* @ts-ignore */}
            <AreaCard w="80%" marginTop="20px">
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={28} fontWeight="500" fontFamily="DM Sans" userSelect="none">🧶</Text>
                  <Text fontSize={22} fontWeight="500" fontFamily="DM Sans" userSelect="none">Formule Classique</Text>
                </Stack>

                <Stack marginRight="5px">
                  <IButton icon={faPenToSquare} width={"40px"} minW={"40px"} onClick={() => navigate("/subscribe")}/>
                </Stack>
              </Stack>

              <Stack direction="row" gap={5} wrap="wrap" marginTop="10px">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">📅</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans" userSelect="none">Abonnement mensuel</Text>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">🔒</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans" userSelect="none">Abonnement sans
                    engagement</Text>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">📈</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans" userSelect="none">3 workflows</Text>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">📧</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans" userSelect="none">Notifications sur la
                    plateforme</Text>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">❌</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans" userSelect="none">Pas de support</Text>
                </Stack>
              </Stack>
            </AreaCard>
          </>
        )}

        {user.is_premium && (
          <>
            {/* @ts-ignore */}
            <AreaCard w="80%">
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={28} fontWeight="500" fontFamily="DM Sans" userSelect="none">🧶</Text>
                  <Text fontSize={22} fontWeight="500" fontFamily="DM Sans" userSelect="none">Formule Premium</Text>
                </Stack>

                <Stack marginRight="5px">
                  <IButton icon={faPenToSquare} width={"40px"} minW={"40px"} onClick={() => navigate("/subscribe")}/>
                </Stack>
              </Stack>

              <Stack direction="row" gap={5} wrap="wrap" marginTop="10px">
                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">📅</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans" userSelect="none">Abonnement mensuel</Text>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">🔒</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans" userSelect="none">Abonnement sans
                    engagement</Text>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">📈</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans" userSelect="none">Workflows illimité</Text>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">📧</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans" userSelect="none">Notifications sur la
                    plateforme</Text>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">❌</Text>
                  <Text fontSize={14} fontWeight="500" fontFamily="DM Sans" userSelect="none">Pas de support</Text>
                </Stack>
              </Stack>

              <Stack direction="row" alignItems="center" marginTop="20px" justify="end">
                <Text fontSize={22} fontWeight="500" fontFamily="DM Sans" userSelect="none">💸</Text>
                <Text fontSize={18} fontWeight="500" fontFamily="DM Sans" userSelect="none">10,00€/mois</Text>
              </Stack>
            </AreaCard>
          </>
        )}

      </Stack>
    </Stack>
  );
}

export default Profile;
