import {useAuthContext} from "../Context/AuthContext";
import {JSX, useEffect, useState} from "react";
import Login from "./Login";
import Register from "./Register";

function Authentication() {
  const {authenticationMode} = useAuthContext();
  const [authenticationElement, setAuthenticationElement] = useState<JSX.Element>(<Login/>);

  useEffect(() => {
    if (authenticationMode === "login") {
      setAuthenticationElement(<Login/>);
    } else if (authenticationMode === "register") {
      setAuthenticationElement(<Register/>);
    }
  }, [authenticationMode]);

  return (
    <>
      {authenticationElement}
    </>
  );
}

export default Authentication