import {Card, Stack, Text} from "@chakra-ui/react";
import {forwardRef} from "react";

const Features = forwardRef((_props, ref) => {

    return (
      // @ts-ignore
      <Stack w={"100vw"} ref={ref}>

        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} h={"100%"} marginTop={"100px"}>
          <Text fontSize={45} fontWeight={"bold"} fontFamily="DM Sans">Discover</Text>

          <Card backgroundColor={"#222222"} textColor={"white"} paddingRight={3} paddingLeft={3} paddingTop={1}
                paddingBottom={1}>
            <Text fontSize={45} fontWeight={"bold"} fontFamily="DM Sans">Your features</Text>
          </Card>

          <Text fontSize={70} fontWeight={"bold"} fontFamily="DM Sans" textColor={"#FF9800"}>.</Text>

        </Stack>

        <Stack direction={"column"} alignItems={"center"} h={"100%"} spacing={100}>

          <Stack direction={"row"} h={"100%"} w={"100%"} alignItems={"center"} justifyContent={"center"} spacing={50}
                 marginRight={150}>
            <Text fontSize={150} fontWeight={"bold"} fontFamily="DM Sans">🦾</Text>
            <Stack direction={"column"} h={"100%"}>
              <Text fontSize={28} fontWeight={"bold"} fontFamily="DM Sans">Workflow management</Text>
              <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">With Area 51, you can create your own
                workflow.</Text>
              <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">You can create your own workflow by
                combining actions and reactions.</Text>
            </Stack>

          </Stack>

          <Stack direction={"row"} h={"100%"} w={"100%"} alignItems={"center"} justifyContent={"center"} spacing={50}
                 marginLeft={150}>
            <Stack direction={"column"} h={"100%"} alignItems={"end"}>
              <Text fontSize={28} fontWeight={"bold"} fontFamily="DM Sans">Action and reaction</Text>
              <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">In your workflow, you can add actions and
                reactions.</Text>
              <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">An action is a trigger that will be
                executed.</Text>
              <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">A reaction is an action that will be
                executed.</Text>
            </Stack>
            <Text fontSize={150} fontWeight={"bold"} fontFamily="DM Sans">🦑</Text>
          </Stack>

          <Stack direction={"row"} h={"100%"} w={"100%"} alignItems={"center"} justifyContent={"center"} spacing={50}
                 marginRight={150}>
            <Text fontSize={150} fontWeight={"bold"} fontFamily="DM Sans">🖇</Text>
            <Stack direction={"column"} h={"100%"}>
              <Text fontSize={28} fontWeight={"bold"} fontFamily="DM Sans">+6 integrations with external services</Text>
              <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">Area 51 is compatible with many external
                services.</Text>
              <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">You can use Area 51 with Github, Google,
                etc.</Text>
            </Stack>
          </Stack>

          <Stack direction={"row"} h={"100%"} w={"100%"} alignItems={"center"} justifyContent={"center"} spacing={50}
                 marginLeft={150}>
            <Stack direction={"column"} h={"100%"} alignItems={"end"}>
              <Text fontSize={28} fontWeight={"bold"} fontFamily="DM Sans">+15 actions / reactions</Text>
              <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">Area 51 has many actions and
                reactions.</Text>
              <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">You can use any action or reaction in your
                workflow.</Text>
            </Stack>
            <Text fontSize={150} fontWeight={"bold"} fontFamily="DM Sans">🧮</Text>
          </Stack>

        </Stack>

      </Stack>

    )
  }
)

export default Features;