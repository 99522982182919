import { Stack, styled } from "@chakra-ui/react";
import { ReactNode } from "react";
import { NavBar } from "../Views/Dashboard/Dashboard.tsx";
import { Outlet } from "react-router-dom";

const Page = styled("div", {
  baseStyle: {
    position: "fixed",
    backgroundColor: "#F5F6F8",
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
  },
});

function PageTemplate({ children }: { children: ReactNode }) {
  return <Page>{children}</Page>;
}

function PageWithNavbar() {
  return (
    <PageTemplate>
      <Stack direction={"row"} h="100vh" padding={0} margin={0}>
        <NavBar />
        <Outlet />
      </Stack>
    </PageTemplate>
  );
}

export { PageWithNavbar };
export default PageTemplate;
