import { SetStateAction, useState } from "react";
import { Button, styled, useToast } from "@chakra-ui/react";
import InputIcon from "../../../Components/Global/Input/InputIcon.tsx";
import { GoMail } from "react-icons/go";
import { useTranslation } from "react-i18next";
import {MdPassword} from "react-icons/md";
import {BiSolidUserAccount} from "react-icons/bi";

const StepContent = styled("div", {
  baseStyle: {
    marginBottom: "15px",
    width: "75%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

const Inputs = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
  },
});

function RegisterStepInformationLogin() {

  const toast = useToast();
  const { t } = useTranslation();

  const [nickname, setNickname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");

  const handleNextStep = () => {

    if (nickname === "") {
      toast({
        title: t("pages.register.toast.nickname.title"),
        description: t("pages.register.toast.nickname.description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (email === "") {
      toast({
        title: t("pages.register.toast.email.title"),
        description: t("pages.register.toast.email.description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (password === "") {
      toast({
        title: t("pages.register.toast.passwordEmpty.title"),
        description: t("pages.register.toast.passwordEmpty.description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (passwordConfirmation === "") {
      toast({
        title: t("pages.register.toast.passwordConfirmation.title"),
        description: t("pages.register.toast.passwordConfirmation.description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (password !== passwordConfirmation) {
      toast({
        title: t("pages.register.toast.password.title"),
        description: t("pages.register.toast.password.description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

  };

  return (
    <StepContent>
      <Inputs>
        <InputIcon
          style={{ fontFamily: "DM Sans" }}
          icon={<BiSolidUserAccount />}
          placeholder={t("labels.nickname")}
          value={nickname}
          onChange={(e: { target: { value: SetStateAction<string> } }) =>
            setNickname(e.target.value)
          }
          type={"text"}
          isRequired={true}
          isInvalid={false}
        />

        <InputIcon
          style={{ fontFamily: "DM Sans" }}
          icon={<GoMail />}
          placeholder={t("labels.email")}
          value={email}
          onChange={(e: { target: { value: SetStateAction<string> } }) =>
            setEmail(e.target.value)
          }
          type={"text"}
          isRequired={true}
          isInvalid={false}
        />

        <InputIcon
          style={{ fontFamily: "DM Sans" }}
          icon={<MdPassword />}
          placeholder={t("labels.password")}
          value={password}
          onChange={(e: { target: { value: SetStateAction<string> } }) =>
            setPassword(e.target.value)
          }
          type={"password"}
          isRequired={true}
          isInvalid={false}
        />

        <InputIcon
          style={{ fontFamily: "DM Sans" }}
          icon={<MdPassword />}
          placeholder={t("labels.passwordConfirmation")}
          value={passwordConfirmation}
          onChange={(e: { target: { value: SetStateAction<string> } }) =>
            setPasswordConfirmation(e.target.value)
          }
          type={"password"}
          isRequired={true}
          isInvalid={false}
        />
      </Inputs>

      <Button
        variant="solid"
        backgroundColor={"#EEEDED"}
        _hover={{ backgroundColor: "#dcdcdc" }}
        marginTop={"20px"}
        borderRadius={"50px"}
        fontWeight={"regular"}
        fontFamily={"DM Sans"}
        width={"45%"}
        onClick={handleNextStep}
      >
        {t("labels.next")}
      </Button>
    </StepContent>
  );
}

export default RegisterStepInformationLogin;
