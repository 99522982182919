import Reaction from "../../Models/Reaction.ts";
import { Text } from "@chakra-ui/react";
import ServiceAny from "../../Components/Services/ServiceAny.tsx";
import ServiceDiscord from "../../Components/Services/ServiceDiscord.tsx";
import ServiceGoogle from "../../Components/Services/ServiceGoogle.tsx";
import {
  FaCloudSun,
  FaGithub,
  FaMicrosoft,
  FaSpotify,
  FaTwitter,
} from "react-icons/fa";

const iconProps = {
  width: "35px",
  height: "35px",
  marginRight: "10px",
};

interface ReactionCardProps {
  reaction: Reaction;
  onClick: (id: string) => void;
}

function ReactionCard({ reaction, onClick }: ReactionCardProps) {
  return (
    <div
      onClick={() => onClick(reaction.id)}
      style={{
        padding: "20px",
        backgroundColor: "#FFFFFF",
        maxWidth: "500px",
        borderRadius: "10px",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {reaction.service_name === "Google" && <ServiceGoogle />}
      {reaction.service_name === "Discord" && <ServiceDiscord />}
      {reaction.service_name === "Weather" && <FaCloudSun style={iconProps} />}
      {reaction.service_name === "Spotify" && <FaSpotify style={iconProps} />}
      {reaction.service_name === "Twitter" && <FaTwitter style={iconProps} />}
      {reaction.service_name === "Microsoft" && (
        <FaMicrosoft style={iconProps} />
      )}
      {reaction.service_name === "Github" && <FaGithub style={iconProps} />}
      {!reaction.service_name && <ServiceAny />}
      <div>
        <Text>{reaction.name}</Text>
      </div>
    </div>
  );
}

export default ReactionCard;
