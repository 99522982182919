import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  HStack,
  IconButton,
  Button,
  DrawerBody,
  DrawerFooter,
} from "@chakra-ui/react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkflowModel from "../../../../Models/Workflow";
import { useCallback, useMemo, useState } from "react";
import ActionModel from "../../../../Models/Action";
import ReactionModel from "../../../../Models/Reaction";
import { FormProvider, useForm } from "react-hook-form";
import PropertyList from "./PropertyList";
import { useTranslation } from "react-i18next";

export const useCardEditDrawer = (workflow: WorkflowModel | undefined) => {
  const [drawerValue, setDrawerValue] = useState<string>();

  const handleOpenDrawer = useCallback((id: string) => {
    setDrawerValue(id);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setDrawerValue(undefined);
  }, []);

  const drawerObject = useMemo(() => {
    if (drawerValue === workflow?.action?.id) return workflow?.action;
    return workflow?.reactions.find((reac) => reac.id === drawerValue);
  }, [drawerValue, workflow]);

  return {
    handleOpenDrawer,
    handleCloseDrawer,
    drawerObject,
    isOpenDrawer: !!drawerValue,
  };
};

export interface Condition {
  label: string;
  value: string;
  name: string;
}

const CardEditDrawer = ({
  isOpen,
  onClose,
  areaData,
  properties,
  onChangeProperties,
}: {
  workflow: WorkflowModel | undefined;
  isOpen: boolean;
  onClose: () => void;
  areaData: ActionModel | ReactionModel | undefined;
  properties: {};
  onChangeProperties: (newProperties: {}) => void;
}) => {
  const availableConditions = useMemo<Condition[]>(
    () =>
      (areaData as ActionModel)?.condition
        ? JSON.parse((areaData as ActionModel).condition)
        : [],
    [areaData]
  );

  const availableDatas = useMemo<Condition[]>(
    () => (areaData?.data ? JSON.parse(areaData.data) : []),
    [areaData]
  );

  const methods = useForm({
    defaultValues: {
      ...Object.fromEntries(
        availableConditions?.map((condition) => {
          return [
            condition.name,
            Object.keys(properties).includes(condition.name)
              ? // @ts-ignore
                properties[condition.name].value
              : "",
          ];
        })
      ),
      ...Object.fromEntries(
        availableDatas?.map((data) => {
          return [
            data.name,
            Object.keys(properties).includes(data.name)
              ? // @ts-ignore
                properties[data.name].value
              : "",
          ];
        })
      ),
    },
  });

  const handleSubmitForm = useCallback(
    (formData: {}) => {
      Object.keys(formData).forEach(
        (key) =>
          // @ts-ignore
          (formData[key] = availableConditions.includes(key)
            ? // @ts-ignore
              { value: formData[key], type: "condition" }
            : // @ts-ignore
              { value: formData[key], type: "data" })
      );
      onChangeProperties(formData);
      onClose();
    },
    [methods, availableConditions]
  );

  const nothingToModify = useMemo(
    () => availableConditions.length == 0 && availableDatas.length == 0,
    [availableConditions, availableDatas]
  );

  const { t } = useTranslation();

  return (
    <Drawer isOpen={isOpen && !nothingToModify} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
            <DrawerHeader>
              <HStack mb="20px" spacing={2}>
                <IconButton
                  background={"none"}
                  onClick={onClose}
                  icon={<FontAwesomeIcon icon={faArrowLeft} />}
                  aria-label={"close-header"}
                />
              </HStack>
            </DrawerHeader>
            <DrawerBody>
              <PropertyList
                available={availableConditions}
                title={t("pages.dashboard.conditions")}
              />
              <PropertyList
                available={availableDatas}
                title={t("pages.dashboard.data")}
              />
            </DrawerBody>
            <DrawerFooter>
              <Button type="submit" fontWeight={"normal"} borderRadius={20}>
                {t("pages.dashboard.save")}
              </Button>
            </DrawerFooter>
          </form>
        </FormProvider>
      </DrawerContent>
    </Drawer>
  );
};

export default CardEditDrawer;
