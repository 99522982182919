import { Box, IconButton, Stack, VStack } from "@chakra-ui/react";
import logo from "../../assets/area.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconDefinition,
  faCodeBranch,
  faHome,
  faUser,
  faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { PathName } from "../../App";
import Header from "../../Components/Global/Header";
import WorkflowCard, {
  WorkflowCardProps,
} from "../../Components/Workflow/WorkflowCard.tsx";
import LastLogs from "../../Components/Dashboard/LastLogs.tsx";
import { useState, useEffect, useMemo } from "react";
import WorkflowServices from "../../Services/WorkflowServices";
import WorkflowModel from "../../Models/Workflow.ts";
import { useTranslation } from "react-i18next";

const Icon = ({
  icon,
  onClick,
  highlighted = false,
}: {
  icon: IconDefinition;
  onClick: () => any;
  highlighted?: boolean;
}): JSX.Element => (
  <IconButton p={8} aria-label={"home"} onClick={onClick} background={"none"}>
    <FontAwesomeIcon
      fontSize={20}
      color={highlighted ? "#E69841" : "#BDBDC1"}
      icon={icon}
    />
  </IconButton>
);

const NavIcon = ({
  icon,
  path,
}: {
  icon: IconDefinition;
  path: PathName;
}): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Icon
      highlighted={pathname === path}
      icon={icon}
      onClick={() => navigate(path)}
    />
  );
};

const NavBar = (): JSX.Element => {
  return (
    <Stack
      background="white"
      w={"20vw"}
      h={"100vh"}
      borderRight={"2px solid #DCDEE3"}
      maxW={100}
    >
      <img
        src={logo}
        style={{
          marginTop: "20px",
          width: "100%",
          height: "80px",
        }}
        alt={"logo"}
      />
      <VStack mb="auto">
        <NavIcon path={"/dashboard"} icon={faHome} />
        <NavIcon path={"/dashboard/workflows"} icon={faCodeBranch} />
        <NavIcon path={"/dashboard/subscribe"} icon={faMoneyCheck} />
        <NavIcon path={"/dashboard/profile"} icon={faUser} />
      </VStack>
    </Stack>
  );
};

function Dashboard() {
  const { t } = useTranslation();

  const workflowCards: WorkflowCardProps[] = [
    {
      title: t("pages.dashboard.activeJobs"),
      buttonText: t("pages.dashboard.activeJobsButton") ?? "",
    },
    {
      title: t("pages.dashboard.updatePlan"),
      description: t("pages.dashboard.updatePlanButton") ?? "",
    },
    {
      title: t("pages.dashboard.limits"),
      buttonText: t("pages.dashboard.limitsButton") ?? "",
    },
  ];

  const [workflows, setWorkflows] = useState<WorkflowModel[]>([]);

  const activeJobs = useMemo(
    () => workflows.filter((w) => w.is_active),
    [workflows]
  );

  useEffect(() => {
    WorkflowServices.getWorkflows().then((res) => {
      setWorkflows(res);
    });
  }, []);

  const navigate = useNavigate();

  return (
    <Stack m={5} spacing={10}>
      <Header>🐙 Dashboard</Header>
      <Stack spacing={10} direction={"row"}>
        <WorkflowCard
          {...workflowCards[0]}
          emoji="👑"
          description={`${activeJobs.length} jobs`}
          onClick={() => {
            navigate("workflows");
          }}
        />
        <WorkflowCard
          emoji="💵"
          {...workflowCards[1]}
          onClick={() => {
            navigate("subscribe");
          }}
        />
        <WorkflowCard
          emoji="🚴🏼‍♂️"
          {...workflowCards[2]}
          description={`${t("pages.dashboard.youAreUsing")} ${
            workflows.length
          }/3 ${t("pages.dashboard.workflowCountBlocker")}`}
          onClick={() => {
            navigate("profile");
          }}
        />
      </Stack>
      <Box>
        <LastLogs />
      </Box>
    </Stack>
  );
}

export { NavBar };
export default Dashboard;
