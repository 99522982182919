import {
  Box,
  Grid,
  GridItem,
  HStack,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMedia } from "react-use";
import Header from "../../Components/Global/Header.tsx";
import IButton from "../../Components/Global/IButton.tsx";
import WorkflowServices from "../../Services/WorkflowServices";
import { useEffect, useMemo, useState } from "react";
import WorkflowModel from "../../Models/Workflow.ts";
import Workflow from "../../Components/Workflow.tsx";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Workflows() {
  const isWide = useMedia("(min-width: 1000px)");
  const navigate = useNavigate();
  const [workflows, setWorkflows] = useState<WorkflowModel[]>([]);

  useEffect(() => {
    WorkflowServices.getWorkflows().then((res) => {
      setWorkflows(res);
    });
  }, []);

  const [search, setSearch] = useState<string>("");

  const filteredWorkflows = useMemo<WorkflowModel[]>(
    () => workflows.filter((workflow) => workflow.name.includes(search)),
    [workflows, search]
  );

  const { t } = useTranslation();

  return (
    <Stack m={5} spacing={10}>
      <Header>
        <HStack w="80vw">
          <Text>🪁 Workflows</Text>
          <HStack ml="auto">
            <IButton
              width="200px"
              icon={faPlus}
              onClick={() => {
                navigate("new");
              }}
            >
              {t("pages.workflows.addAWorkflow")}
            </IButton>
            <Box
              w="100%"
              maxW={"350px"}
              fontSize={22}
              fontWeight={"light"}
              color="#9FA1A5"
              borderRadius="10px"
              background={"#EAEBEE"}
            >
              <HStack px={"10px"} h="45px" spacing={4}>
                <FontAwesomeIcon icon={faSearch} />
                <Input
                  value={search}
                  onChange={(value) => setSearch(value.target.value)}
                  placeholder={t("pages.workflows.search") ?? ""}
                  w="100%"
                />
              </HStack>
            </Box>
          </HStack>
        </HStack>
      </Header>
      <Grid
        m={!isWide ? "auto" : undefined}
        templateColumns={isWide ? "repeat(6, 2fr)" : undefined}
        gap={6}
      >
        {filteredWorkflows.map((workflow) => (
          <GridItem key={JSON.stringify(workflow)}>
            <Workflow
              id={workflow.id}
              time={new Date()}
              description={workflow.description}
              title={workflow.name}
            />
          </GridItem>
        ))}
      </Grid>
      <Box w="100%" mt="auto" mb="2%" mx="auto">
        <Text textAlign={"center"} mx="200px">
          {t("pages.dashboard.youAreUsing")} {workflows.length}/3{" "}
          {t("pages.dashboard.workflowCountBlocker")}
        </Text>
      </Box>
    </Stack>
  );
}

export default Workflows;
