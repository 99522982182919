import WorkflowModel from "../../../Models/Workflow.js";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WorkflowServices from "../../../Services/WorkflowServices";
import {
  Center,
  CircularProgress,
  HStack,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import BackgroundWorkflow from "../../../assets/background_workflow.png";
import ReactionCard from "../../../Components/Workflow/ReactionCard";
import ActionCard from "../../../Components/Workflow/ActionCard";
import { faFileCirclePlus, faWind } from "@fortawesome/free-solid-svg-icons";
import CardEditDrawer, { useCardEditDrawer } from "./Drawer/index";
import AddServiceButton from "./AddServiceButton";
import UserServices from "../../../Services/User";
import Action from "../../../Models/Action";
import Reaction from "../../../Models/Reaction";
import WorkflowEditHeader from "./Header";
import { useTranslation } from "react-i18next";

function Workflow() {
  const { t } = useTranslation();

  const defaultValues = {
    action: undefined,
    emoji: "👻",
    reactions: [],
    id: "new",
    description: t("pages.workflows.addADescription"),
    is_active: false,
    name: t("pages.workflows.newWorkflow"),
    reactions_delay_ms: 100,
    user_id: "",
    data: "{}",
    condition: "{}",
  };

  const navigate = useNavigate();
  const toast = useToast();
  let { workflowId } = useParams();

  const [workflow, setWorkflow] = useState<WorkflowModel>(defaultValues);

  useEffect(() => {
    if (workflowId === "new") {
      UserServices.getUser().then((res) => {
        setWorkflow({ ...defaultValues, user_id: res?.user.id });
      });
      return;
    }
    WorkflowServices.getWorkflow(workflowId as unknown as string)
      .then((res: WorkflowModel) => {
        setWorkflow(res);
        let properties = {};
        Object.entries(JSON.parse(res.data)).forEach(
          ([key, value]) =>
            // @ts-ignore
            (properties[key] = { value, type: "data" })
        );
        Object.entries(JSON.parse(res.condition)).forEach(
          ([key, value]) =>
            // @ts-ignore
            (properties[key] = { value, type: "condition" })
        );
        setProperties(properties);
      })
      .catch(() =>
        toast({ title: t("pages.workflows.anErrorOccured"), status: "error" })
      );
  }, [workflowId]);

  const { isOpenDrawer, drawerObject, handleCloseDrawer, handleOpenDrawer } =
    useCardEditDrawer(workflow);

  const handleUpdateArea = useCallback(
    ({ action, reaction }: { action?: Action; reaction?: Reaction }) => {
      setWorkflow((prevWorkflow) => {
        if (action) return { ...prevWorkflow, action };
        if (reaction) {
          const updatedReactions = !workflow.reactions
            .map((reac) => reac.id)
            .includes(reaction.id)
            ? [...prevWorkflow.reactions, reaction]
            : prevWorkflow.reactions.filter((reac) => reac.id !== reaction.id);
          return {
            ...prevWorkflow,
            reactions: updatedReactions,
          };
        }
        return prevWorkflow;
      });
      if (action) handleOpenDrawer(action.id);
      if (reaction) handleOpenDrawer(reaction.id);
    },
    [workflow]
  );

  const toggleWorkflowDelete = useCallback(() => {
    WorkflowServices.deleteWorkflow(workflow.id).then(() => {
      navigate("/dashboard/workflows");
    });
  }, [navigate, workflow]);

  const [properties, setProperties] = useState({});
  const handleChangeProperties = useCallback((newProperties: {}) => {
    setProperties(newProperties);
  }, []);

  if (workflow.id !== "new" && !workflow.action)
    return (
      <Center m="auto">
        <CircularProgress />
      </Center>
    );

  return (
    <Stack w={"100%"} marginLeft={-2}>
      <WorkflowEditHeader
        properties={properties}
        onDeleteWorkflow={() => toggleWorkflowDelete()}
        onToggleActive={() =>
          setWorkflow((prev) => ({
            ...prev,
            is_active: !prev.is_active,
          }))
        }
        workflow={workflow}
        onChangeEmoji={(emoji) => setWorkflow((prev) => ({ ...prev, emoji }))}
        onChangeName={(name) => setWorkflow((prev) => ({ ...prev, name }))}
        onChangeDescription={(description) =>
          setWorkflow((prev) => ({ ...prev, description }))
        }
        onChangeReactionMs={(reactions_delay_ms) =>
          setWorkflow((prev) => ({
            ...prev,
            reactions_delay_ms: +reactions_delay_ms,
          }))
        }
      />
      <Stack
        backgroundColor={"#FAFAFA"}
        width={"100%"}
        marginTop={0}
        borderTop={"2px solid #DCDEE3"}
        height={"100%"}
        position={"relative"}
      >
        <img
          draggable="false"
          src={BackgroundWorkflow}
          style={{
            width: "100%",
            height: "calc(100vh - 90px)",
            objectFit: "cover",
            objectPosition: "center",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
        <HStack
          zIndex={1}
          m="auto"
          style={{
            padding: "50px",
            display: "flex",
            gap: "50px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {workflow?.action && (
            <ActionCard onClick={handleOpenDrawer} action={workflow.action} />
          )}

          <VStack>
            {workflow?.reactions?.map((reaction) => (
              <ReactionCard
                onClick={handleOpenDrawer}
                reaction={reaction}
                key={JSON.stringify(reaction)}
              />
            ))}
          </VStack>
        </HStack>
      </Stack>
      {isOpenDrawer && (
        <CardEditDrawer
          properties={properties}
          onChangeProperties={handleChangeProperties}
          isOpen={isOpenDrawer}
          onClose={handleCloseDrawer}
          areaData={drawerObject}
          workflow={workflow}
        />
      )}
      <HStack
        position={"absolute"}
        left="calc(100vw - 120px)"
        top="calc(100vh - 60px)"
        mx="20px"
        ml="auto"
      >
        <AddServiceButton
          highlightAreas={workflow.action ? [workflow.action.id] : []}
          onAddArea={handleUpdateArea}
          icon={faFileCirclePlus}
          type="actions"
        />
        <AddServiceButton
          highlightAreas={workflow?.reactions?.map((reac) => reac.id)}
          onAddArea={handleUpdateArea}
          icon={faWind}
          type="reactions"
        />
      </HStack>
      <Text zIndex={1} textAlign={"center"} mx="200px" mb="20px">
        {t("pages.dashboard.youAreUsing")} {workflow?.action ? 1 : 0}/1 actions
        & {workflow?.reactions?.length}/2 reactions{" "}
        {t("pages.dashboard.updatePlan2")}
      </Text>
    </Stack>
  );
}

export default Workflow;
