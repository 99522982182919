import {Card, Stack, Text} from "@chakra-ui/react";
import BurgerDePapa from "../../assets/burgerdepapa.png";
import Spotify from "../../assets/spotify.png";
import Epitech from "../../assets/epitech.png";
import Notion from "../../assets/notion.png";
import Amazon from "../../assets/amazon.png";

function Customers() {

  return (
    <Stack w={"100vw"} backgroundColor={"#222222"} alignItems={"center"} justifyContent={"center"}>
      <Stack direction={"row"} alignItems={"center"} h={"100%"} marginTop={"40px"} marginBottom={"20px"}>
        <Text fontSize={30} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Do like them,</Text>
        <Text fontSize={30} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>use</Text>

        <Card backgroundColor={"#FF9800"} textColor={"white"} paddingRight={3} paddingLeft={3} paddingTop={1}
              paddingBottom={1}>
          <Text fontSize={30} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>AREA 51</Text>
        </Card>

        <Text fontSize={45} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>.</Text>

      </Stack>

      <Stack direction={"row"} spacing={20} marginBottom={"40px"}>

        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <img src={BurgerDePapa} alt={"github"} height={30} width={50}/>
          <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Les Burger de Papa</Text>
        </Stack>

        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <img src={Spotify} alt={"google"} height={30} width={30}/>
          <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Spotify</Text>
        </Stack>

        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <img src={Epitech} alt={"azure"} height={30} width={80}/>
          <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Epitech</Text>
        </Stack>

        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <img src={Notion} alt={"openweather"} height={30} width={30}/>
          <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Notion</Text>
        </Stack>

        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <img src={Amazon} alt={"discord"} height={30} width={60}/>
          <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Amazon</Text>
        </Stack>

      </Stack>

    </Stack>
  )
}

export default Customers
