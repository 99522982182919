import Paywall from "../../assets/paywall.svg";
import {Card, Stack, Text} from "@chakra-ui/react";

function PaywallInfo() {
  
  return (
    <Stack flexDirection={"row"} w={"100%"} alignItems={"center"} justifyContent={"center"} marginTop={"150px"}
           marginBottom={"150px"}>

      <img src={Paywall} alt={"paywall"} width={600}/>

      <Stack marginRight={100} flexDirection={"column"} h={"100%"}>

        <Stack direction={"row"} alignItems={"center"} h={"100%"}>
          <Text fontSize={22} fontWeight={"bold"} fontFamily="DM Sans">Always better with</Text>
          <Card backgroundColor={"#222222"} textColor={"white"} paddingRight={3} paddingLeft={3} paddingTop={1}
                paddingBottom={1}>
            <Text fontSize={22} fontWeight={"bold"} fontFamily="DM Sans">PRO</Text>
          </Card>
          <Text fontSize={22} fontWeight={"bold"} fontFamily="DM Sans">and</Text>
          <Card backgroundColor={"#222222"} textColor={"white"} paddingRight={3} paddingLeft={3} paddingTop={1}
                paddingBottom={1}>
            <Text fontSize={22} fontWeight={"bold"} fontFamily="DM Sans">PREMIUM</Text>
          </Card>
          <Text fontSize={40} fontWeight={"bold"} fontFamily="DM Sans" textColor={"#FF9800"}>.</Text>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} h={"100%"} w={"420px"}>
          <Text fontSize={42} fontWeight={800} fontFamily="DM Sans" textTransform={"uppercase"}>Play a beautiful life
            and automate your life
            with Area 51</Text>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} h={"100%"} w={"350px"}>
          <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">With Area 51, you can automate your life and
            create your own workflow.</Text>
        </Stack>

      </Stack>

    </Stack>
  )
}

export default PaywallInfo;