import {Card, Stack, Text} from "@chakra-ui/react";

function PremiumPlan() {

  return (
    <Stack direction={"column"} h={"100%"} alignItems={"center"} width={"250px"}
           backgroundColor={"#EDF2F7"} paddingTop={5} paddingBottom={5} borderRadius={5} shadow={"xl"}
           marginLeft={"50px"} marginTop={"50px"} height={"500px"}
    >

      <Stack direction={"column"} alignItems={"center"} justifyContent={"space-between"}
             borderBottom={"2px solid #CCD6E0"} paddingBottom={5} width={"100%"}
      >
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} h={"100%"}>
          <Card backgroundColor={"#222222"} textColor={"white"} paddingRight={3} paddingLeft={3} paddingTop={1}
                paddingBottom={1}>
            <Text fontSize={36} fontWeight={"bold"} fontFamily="DM Sans">Premium</Text>
          </Card>

          <Text fontSize={50} fontWeight={"bold"} fontFamily="DM Sans" textColor={"#FF9800"}>.</Text>

        </Stack>
        <Text fontSize={45} fontWeight={"bold"} fontFamily="DM Sans">$10</Text>
        <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">Per month</Text>
      </Stack>

      <Stack direction={"column"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}
             marginTop={"30px"}>
        <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">10 workflows</Text>
        <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">10 actions</Text>
        <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">10 reactions</Text>
        <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">10 integrations</Text>
        <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">Support prioritized</Text>
      </Stack>
    </Stack>
  )
}

export default PremiumPlan
