import {URL_Server} from "../Services/Api.ts";

export const getGoogle = () => {
  const rootUrl = `${URL_Server}/auth/google`

  const options = {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`
  }

  const qs = new URLSearchParams(options)

  return `${rootUrl}?${qs.toString()}`
}

export const getGithub = () => {
  const rootUrl = `${URL_Server}/auth/github`

  const options = {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`
  }

  const qs = new URLSearchParams(options)

  return `${rootUrl}?${qs.toString()}`
}

export const getSpotify = () => {
  const rootUrl = `${URL_Server}/auth/spotify`

  const options = {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`
  }

  const qs = new URLSearchParams(options)

  return `${rootUrl}?${qs.toString()}`
}

export const getTwitter = () => {
  const rootUrl = `${URL_Server}/auth/twitter`

  const options = {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`
  }

  const qs = new URLSearchParams(options)

  return `${rootUrl}?${qs.toString()}`
}

export const getMicrosoft = () => {
  const rootUrl = `${URL_Server}/auth/microsoft`

  const options = {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`
  }

  const qs = new URLSearchParams(options)

  return `${rootUrl}?${qs.toString()}`
}

export const getStripeToPremium = () => {
  const rootUrl = `${URL_Server}/subscription/create-checkout-session`

  const options = {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`
  }

  const qs = new URLSearchParams(options)

  return `${rootUrl}?${qs.toString()}`
}

export const getStripeToBasic = () => {
  const rootUrl = `${URL_Server}/subscription/create-portal-session`

  const options = {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`
  }

  const qs = new URLSearchParams(options)

  return `${rootUrl}?${qs.toString()}`
}