import PageTemplate from "../Components/Global/PageTemplate.tsx";
import {Stack, styled, Text} from "@chakra-ui/react";
import Card from "../Components/Global/Card/Card.tsx";
import { useTranslation } from "react-i18next";
import Logo from "../assets/area.svg";
import { useAuthContext } from "../Context/AuthContext.tsx";
import RegisterStepInformationLogin from "../Components/Register/Steps/InformationLogin.tsx";
import {URL_Server} from "../Services/Api.ts";
import {FcGoogle} from "react-icons/fc";
import {BiLogoGithub} from "react-icons/bi";

const RegisterContent = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
});

const SwitchContent = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});

const TextSwitch = styled("p", {
  baseStyle: {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: "400",
    color: "#000000",
    marginTop: "10px",
  },
});

const TextSwitchLink = styled("p", {
  baseStyle: {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: "400",
    color: "#FD8147",
    marginTop: "10px",
    textDecoration: "underline",
    marginLeft: "5px",
    cursor: "pointer",
  },
});

const PageTitle = styled("p", {
  baseStyle: {
    fontFamily: "DM Sans",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#000000",
    marginTop: "10px",
  },
});

function Register() {
  const {
    setAuthenticationMode,
    authenticationStatus,
    setAuthenticationStatus,
  } = useAuthContext();
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <RegisterContent>
        <Card
          style={{
            width: "400px",
            paddingTop: "30px",
            paddingBottom: "50px",
          }}
        >
          <img src={Logo} alt="Logo" style={{ width: "90px" }} />

          <PageTitle>{t("pages.register.title")}</PageTitle>

          {authenticationStatus === 0 && <RegisterStepInformationLogin />}


          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} marginTop={"20px"}
                 padding={"10px"} cursor={"pointer"} borderRadius={4} boxShadow={"0px 0px 3px rgba(0,0,0,0.25)"}
                 onClick={() => {
                   window.location.href = `${URL_Server}/auth/google`;
                 }}
                 width={"60%"}
          >
            <FcGoogle size={24}/>
            <Text fontSize={"14px"} fontWeight={"bold"} fontFamily={"DM Sans"} textColor={"#707070"}
                  marginLeft={"5px"}>{t("pages.login.google")}</Text>
          </Stack>

          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} marginTop={"20px"}
                 padding={"10px"} cursor={"pointer"} borderRadius={4} boxShadow={"0px 0px 3px rgba(0,0,0,0.25)"}
                 onClick={() => {
                   window.location.href = `${URL_Server}/auth/microsoft`;
                 }}
                 width={"65%"} backgroundColor={"#195bbe"}
          >
            <BiLogoGithub size={24} color={"#ffffff"}/>
            <Text fontSize={"14px"} fontWeight={"bold"} fontFamily={"DM Sans"} textColor={"#ffffff"}
                  marginLeft={"5px"}>{t("pages.login.microsoft")}</Text>
          </Stack>
          {/*
          {authenticationStatus === 1 && <div>Step 2</div>}
          {authenticationStatus === 2 && <div>Step 3</div>}

          <RegisterStepper />
          */}
        </Card>

        <SwitchContent>
          <TextSwitch>{t("pages.register.switch")}</TextSwitch>
          <TextSwitchLink
            onClick={() => {
              setAuthenticationStatus(0);
              setAuthenticationMode("login");
            }}
          >
            {t("pages.register.switchLink")}
          </TextSwitchLink>
        </SwitchContent>
      </RegisterContent>
    </PageTemplate>
  );
}

export default Register;
