import {
  PopoverContent,
  PopoverBody,
  Accordion,
  AccordionItem,
  AccordionButton,
  HStack,
  AccordionPanel,
  Button,
  Text,
  useToast,
  IconButton,
  Popover,
  PopoverTrigger,
} from "@chakra-ui/react";
import {
  IconDefinition,
  faCheck,
  faLock,
  faPlus,
  faRemove,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Action from "../../../Models/Action";
import Reaction from "../../../Models/Reaction";
import ServiceModel from "../../../Models/Services";
import Services from "../../../Services/Services";
import { useEffect, useMemo, useState } from "react";
import { getServiceColor } from "../utils";
import { useTranslation } from "react-i18next";

const AddServiceButton = ({
  icon,
  type,
  onAddArea,
  highlightAreas,
}: {
  highlightAreas: string[];
  icon: IconDefinition;
  type: "actions" | "reactions";
  onAddArea: ({
    action,
    reaction,
  }: {
    action?: Action;
    reaction?: Reaction;
  }) => any;
}) => {
  const toast = useToast();
  const [services, setServices] = useState<ServiceModel[]>();

  const { t } = useTranslation();

  useEffect(() => {
    Services.getServices()
      .then((res) => {
        setServices(res);
      })
      .catch(() =>
        toast({
          title: t("pages.workflows.anErrorOccured"),
          status: "error",
        })
      );
  }, []);

  const selectIcon = useMemo<IconDefinition>(
    () => (type === "actions" ? faRotate : faPlus),
    [type]
  );

  const deselectIcon = useMemo<IconDefinition>(
    () => (type === "actions" ? faCheck : faRemove),
    [type]
  );

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          borderRadius={30}
          icon={<FontAwesomeIcon icon={icon} />}
          aria-label={""}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <Accordion allowToggle>
            {services?.map((service, sid) => (
              <AccordionItem
                key={service.id}
                isDisabled={service[type].length === 0}
              >
                <AccordionButton>
                  <HStack m="auto">
                    <Text color={getServiceColor(sid)} fontWeight={"bold"}>
                      {service.name}
                    </Text>
                    {service.auth_required && (
                      <FontAwesomeIcon color="#DCDEE3" icon={faLock} />
                    )}
                  </HStack>
                </AccordionButton>
                <AccordionPanel maxHeight="400px" overflow={"auto"}>
                  {service[type]?.map((item: Action | Reaction, aid) => (
                    <Button
                      isDisabled={
                        highlightAreas?.includes(item.id) && type === "actions"
                      }
                      onClick={() =>
                        onAddArea({ [`${type.slice(0, -1)}`]: item })
                      }
                      leftIcon={
                        <FontAwesomeIcon
                          icon={
                            highlightAreas?.includes(item.id)
                              ? deselectIcon
                              : selectIcon
                          }
                        />
                      }
                      key={item.id}
                      fontWeight={"regular"}
                      background={getServiceColor(sid, aid)}
                      m={1}
                    >
                      {item.name}
                    </Button>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AddServiceButton;
