import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Input,
  Text,
  Button,
  Stack,
  useToast,
  IconButton,
  HStack,
  EditablePreview,
  Editable,
  EditableInput,
} from "@chakra-ui/react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../../Components/Global/Header";
import WorkflowModel from "../../../Models/Workflow";
import WorkflowServices from "../../../Services/WorkflowServices";
import EmojiPicker from "emoji-picker-react";
import { useCallback } from "react";
import { BiSolidTrashAlt, BiSolidSave } from "react-icons/bi";
import { SiRunkit } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const WorkflowEditHeader = ({
  onChangeName,
  onChangeDescription,
  onChangeEmoji,
  onToggleActive,
  onDeleteWorkflow,
  onChangeReactionMs,
  workflow,
  properties,
}: {
  onChangeName: (name: string) => any;
  onChangeDescription: (description: string) => any;
  onChangeEmoji: (emoji: string) => any;
  onChangeReactionMs: (reaction_ms: string) => any;
  onToggleActive: () => any;
  onDeleteWorkflow: () => any;
  workflow: WorkflowModel;
  properties: {};
}): JSX.Element => {
  const navigate = useNavigate();
  const toast = useToast();

  const { t } = useTranslation();

  const handleSaveWorkflow = useCallback(() => {
    if (!workflow.action || workflow.reactions.length === 0) {
      toast({
        title: t("pages.dashboard.pleaseAdd"),
        status: "info",
      });
      return;
    }

    // @ts-ignore
    let data = {};
    // @ts-ignore
    let condition = {};
    Object.entries(properties).map(([key, value]) => {
      // @ts-ignore
      if (value.type === "data") data[key] = value.value;
      // @ts-ignore
      if (value.type === "condition") condition[key] = value.value;
    });

    workflow.id === "new"
      ? WorkflowServices.createWorkflow({
          name: workflow.name,
          emoji: workflow.emoji,
          description: workflow.description,
          data: JSON.stringify(data),
          condition: JSON.stringify(condition),
          action_id: workflow.action.id,
          reaction_ids: workflow.reactions.map((reac) => reac.id),
          reactions_delay_ms: workflow.reactions_delay_ms,
        })
          .then((res) => {
            toast({
              title: `${res.name} ${t("pages.dashboard.wasCreated")}`,
              status: "success",
            });
            navigate(`/dashboard/workflows/${res.id}`);
          })
          .catch(() => {
            toast({
              title: t("pages.workflows.anErrorOccured"),
              status: "error",
            });
          })
      : WorkflowServices.updateWorkflow({
          id: workflow.id,
          action_id: workflow.action.id,
          description: workflow.description,
          is_active: workflow.is_active,
          name: workflow.name,
          emoji: workflow.emoji,
          data: JSON.stringify(data),
          condition: JSON.stringify(condition),
          reaction_ids: workflow.reactions.map((reac) => reac.id),
          reactions_delay_ms: workflow.reactions_delay_ms,
        })
          .then(() => {
            toast({
              title: t("pages.dashboard.successUpdate"),
              status: "success",
            });
          })
          .catch(() => {
            toast({
              title: t("pages.workflows.anErrorOccured"),
              status: "error",
            });
          });
  }, [workflow, properties]);

  return (
    <Stack
      zIndex={10}
      w="100%"
      paddingLeft={"50px"}
      direction="row"
      justifyContent="space-between"
      h={"90px"}
      alignItems={"center"}
    >
      <Header>
        <HStack>
          <IconButton
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
            onClick={() => navigate("/dashboard/workflows")}
            aria-label={""}
          />
          <HStack>
            <Popover trigger="hover">
              <PopoverTrigger>
                <Text _hover={{ cursor: "pointer" }}>{workflow.emoji}</Text>
              </PopoverTrigger>
              <PopoverContent w={"100%"}>
                <PopoverBody>
                  <EmojiPicker
                    onEmojiClick={(emoji) => onChangeEmoji(emoji.emoji)}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Editable defaultValue={workflow.name}>
              <Input
                as={EditableInput}
                onChange={(event) => onChangeName(event.target.value)}
                value={workflow.name}
              />
              <EditablePreview />
            </Editable>
          </HStack>
        </HStack>
      </Header>
      <Editable defaultValue={workflow.description}>
        <Input
          as={EditableInput}
          onChange={(event) => onChangeDescription(event.target.value)}
          placeholder="Description"
          value={workflow.description}
        />
        <EditablePreview />
      </Editable>
      <Editable value={`${workflow.reactions_delay_ms}`}>
        <Input
          type="number"
          as={EditableInput}
          onChange={(event) => onChangeReactionMs(event.target.value)}
          placeholder="Reaction delay (ms)"
          value={workflow.reactions_delay_ms}
        />
        <EditablePreview />
      </Editable>
      <Stack direction="row" mr="5%">
        {workflow.id !== "new" && (
          <Button background={"none"} size={"lg"} onClick={onToggleActive}>
            <SiRunkit
              size={28}
              color={workflow?.is_active ? "#1CC500" : "#FD3F3F"}
              transition={"background-color 1000ms linear"}
              style={{
                transition: "background-color 1000ms linear",
              }}
            />
          </Button>
        )}
        {workflow.id !== "new" && (
          <Button background={"none"} size={"lg"} onClick={onDeleteWorkflow}>
            <BiSolidTrashAlt
              size={28}
              color="#FD3F3F"
              transition={"background-color 1000ms linear"}
              style={{
                transition: "background-color 1000ms linear",
              }}
            />
          </Button>
        )}
        <Button
          background={"none"}
          size={"lg"}
          onClick={() => handleSaveWorkflow()}
        >
          <BiSolidSave
            size={28}
            color="purple"
            transition={"background-color 1000ms linear"}
            style={{
              transition: "background-color 1000ms linear",
            }}
          />
        </Button>
      </Stack>
    </Stack>
  );
};

export default WorkflowEditHeader;
