import {
  VStack,
  Text,
  Box,
  FormControl,
  Input,
  HStack,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { Condition } from ".";
import { useTranslation } from "react-i18next";

const PropertyList = ({
  available,
  title,
}: {
  available: Condition[];
  title: string;
}) => {
  const { control } = useFormContext();

  const { t } = useTranslation();

  return (
    <VStack spacing={6} fontSize={14} fontWeight={"medium"}>
      {available.length && <Text>{title}</Text>}
      <VStack>
        {available.length &&
          available.map((property) => (
            <FormControl key={property.name}>
              <HStack m="10px">
                <Text color="gray.400">Si</Text>
                <Text fontWeight={"bold"} m="auto">
                  {property.label}
                </Text>
              </HStack>
              <HStack m="10px">
                <Text m="auto" color="gray.400">
                  {t("pages.dashboard.is")}
                </Text>
                <Box m="auto">
                  <Controller
                    name={property.name}
                    control={control}
                    render={({ field }) => (
                      <Input {...field} background="gray.200" />
                    )}
                  ></Controller>
                </Box>
              </HStack>
            </FormControl>
          ))}
      </VStack>
    </VStack>
  );
};

export default PropertyList;
