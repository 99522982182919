import Api from "./Api.ts";

export default class UserServices {
  static getUser = () => {
    return Api.get(`user`);
  };

  static getUserAuth = (token: string) => {
    return Api.getWithoutToken(`user`, token);
  }

  static updateUser = (data: any) => {
    return Api.patch(`user`, data);
  };

  static logout = () => {
    return Api.get(`auth/logout`);
  }
}
