import {FcGoogle} from "react-icons/fc";

function ServiceDiscord() {

  return (
    <FcGoogle
      style={{
        width: "35px",
        height: "35px",
        marginRight: "10px",
      }}
    />
  )
}

export default ServiceDiscord