import {Button, Stack, Text} from "@chakra-ui/react";
import Logo from "../../assets/area.svg";
import Links from "../../assets/links.svg";
import Processing from "../../assets/processing.svg";
import PaywallInfo from "../../Components/Landing/PaywallInfo.tsx";
import Partners from "../../Components/Landing/Partners.tsx";
import Features from "../../Components/Landing/Features.tsx";
import Customers from "../../Components/Landing/Customers.tsx";
import Pricing from "../../Components/Landing/Pricing.tsx";
import Focus from "../../Components/Landing/Focus.tsx";
import Footer from "../../Components/Landing/Footer.tsx";
import {useRef} from "react";
import Contact from "../../Components/Landing/Contact.tsx";
import {useNavigate} from "react-router-dom";

function LandingPage() {

  const navigate = useNavigate();

  const homeRef = useRef();
  const featuresRef = useRef();
  const pricingRef = useRef();
  const contactRef = useRef();

  const scrollToContact = () => {
    // @ts-ignore
    contactRef.current.scrollIntoView({behavior: "smooth", block: "start"});
  }

  const scrollToHome = () => {
    // @ts-ignore
    homeRef.current.scrollIntoView({behavior: "smooth", block: "start"});
  }

  const scrollToFeatures = () => {
    // @ts-ignore
    featuresRef.current.scrollIntoView({behavior: "smooth", block: "start"});
  }

  const scrollToPricing = () => {
    // @ts-ignore
    pricingRef.current.scrollIntoView({behavior: "smooth", block: "start"});
  }

  return (

    <Stack backgroundColor={"#F5F6F8"} minH={"100vh"}>
      {/* @ts-ignore */}
      <Stack w={"100vw"} h={"70vh"} backgroundColor={"#222222"} ref={homeRef}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>

          <Stack direction={"row"} spacing={10} marginLeft={50} alignItems={"center"}>
            <img src={Logo} alt={"logo"} height={50}/>
          </Stack>

          <Stack direction={"row"} spacing={10} marginRight={50}>

            <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"} onClick={() => scrollToHome()}
                  cursor={"pointer"} userSelect={"none"}>
              Home
            </Text>
            <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"} onClick={() => scrollToFeatures()}
                  cursor={"pointer"} userSelect={"none"}>
              Features
            </Text>
            <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"} onClick={() => scrollToPricing()}
                  cursor={"pointer"} userSelect={"none"}>
              Pricing
            </Text>
            <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"} onClick={() => scrollToContact()}
                  cursor={"pointer"} userSelect={"none"}>
              Contact
            </Text>

            <Stack direction={"row"} spacing={10}>
              <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"} onClick={() => navigate("/dashboard")}
                    cursor={"pointer"} userSelect={"none"}>
                Login
              </Text>
            </Stack>

          </Stack>

        </Stack>

        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} h={"100%"} position={"relative"}>

          <Stack direction={"column"} marginRight={100}>
            <Text fontSize={45} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Automate your life</Text>
            <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Automate your life with
              Area 51, the best automation platform.</Text>

            <Stack direction={"row"} spacing={10} marginTop={10}>
              <Button fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">Get started</Button>
            </Stack>
          </Stack>

          <Stack direction={"column"} spacing={10} alignItems={"center"}>
            <img src={Processing} alt={"processing"} width={400}/>
          </Stack>

          <img src={Links} alt={"links"} width={200} style={{
            position: "absolute",
            bottom: -100,
            left: 100,
            zIndex: 1
          }}/>
        </Stack>

      </Stack>

      <PaywallInfo/>

      <Partners/>

      <Features ref={featuresRef}/>

      <Customers/>

      <Pricing ref={pricingRef}/>

      <Focus/>

      <Contact ref={contactRef}/>

      <Footer/>

    </Stack>
  );
}

export default LandingPage;