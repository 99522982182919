import {Card, Stack, Text} from "@chakra-ui/react";
import Github from "../../assets/github.png";
import Google from "../../assets/google.png";
import Azure from "../../assets/azure.png";
import OpenWeather from "../../assets/openweather.png";
import Discord from "../../assets/discord.png";

function Partners() {
  
  return (
    <Stack w={"100vw"} backgroundColor={"#222222"} alignItems={"center"} justifyContent={"center"}>
      <Stack direction={"column"} alignItems={"center"} h={"100%"} marginTop={"40px"} marginBottom={"20px"}>
        <Text fontSize={30} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Our partners and
          integrations,</Text>
        <Stack direction={"row"} alignItems={"center"} marginTop={"-15px"}>
          <Text fontSize={30} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>just for</Text>

          <Card backgroundColor={"#FF9800"} textColor={"white"} paddingRight={3} paddingLeft={3} paddingTop={1}
                paddingBottom={1}>
            <Text fontSize={30} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>You ❤️</Text>
          </Card>

          <Text fontSize={45} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>.</Text>
        </Stack>

      </Stack>

      <Stack direction={"row"} spacing={20} marginBottom={"40px"}>

        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <img src={Github} alt={"github"} height={30} width={30}/>
          <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Github</Text>
        </Stack>

        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <img src={Google} alt={"google"} height={30} width={30}/>
          <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Google</Text>
        </Stack>

        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <img src={Azure} alt={"azure"} height={30} width={30}/>
          <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Azure</Text>
        </Stack>

        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <img src={OpenWeather} alt={"openweather"} height={30} width={50}/>
          <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>OpenWeather</Text>
        </Stack>

        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <img src={Discord} alt={"discord"} height={30} width={30}/>
          <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans" textColor={"white"}>Discord</Text>
        </Stack>

      </Stack>

    </Stack>
  )
}

export default Partners
