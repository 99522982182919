import { Box, Stack, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

function Header({
  emoji,
  alignEmoji = false,
  children,
}: {
  emoji?: string;
  alignEmoji?: boolean;
  children: ReactNode;
}) {
  return (
    <Stack
      color="#252529"
      fontWeight={"bold"}
      fontSize={28}
      direction={"row"}
      alignItems={alignEmoji ? "center" : undefined}
    >
      {!!emoji && <Text fontSize={36}>{emoji}</Text>}
      <Box m={2}>{children}</Box>
    </Stack>
  );
}

export default Header;
