import { Button, Text, HStack } from "@chakra-ui/react";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AreaCard from "../Components/Global/AreaCard";
import Header from "../Components/Global/Header";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";
import moment from "moment";
import integrations from "../assets/integrations.svg";
import { useNavigate } from "react-router-dom";

const Workflow = ({
  id,
  title,
  time,
  description,
}: {
  id: string;
  title: string;
  time: Date;
  description?: string;
}) => {
  const navigate = useNavigate();
  const timeElapsed = useMemo<string>(() => {
    const since = time ?? new Date();
    return moment(new Date(since)).fromNow();
  }, [time]);

  return (
    // @ts-ignore
    <AreaCard h="200px" w="380px">
      <Header>
        <HStack maxW={350}>
          <Text fontSize={24}>{title}</Text>
        </HStack>
      </Header>
      <Text color="#747474">{description}</Text>
      <HStack mt="auto" color="#A9A9A9">
        <HStack fontSize={"14px"} m="10px">
          <FontAwesomeIcon icon={faClock} />
          <Text>{timeElapsed}</Text>
        </HStack>
        <HStack ml="auto">
          <img src={integrations} alt={"integrations"} />
          <Button
            onClick={() => navigate(`${id}`)}
            fontWeight={"thin"}
            background={"none"}
          >
            <FontAwesomeIcon
              fontSize={26}
              color="#BDBDC1"
              icon={faArrowRight}
            />
          </Button>
        </HStack>
      </HStack>
    </AreaCard>
  );
};

export default Workflow;
