import {Card, Stack, Text} from "@chakra-ui/react";
import FreeTier from "./Pricing/FreeTier.tsx";
import PremiumPlan from "./Pricing/PremiumPlan.tsx";
import {forwardRef} from "react";

const Pricing = forwardRef((_props, ref) => {

    return (
      // @ts-ignore
      <Stack direction={"column"} marginTop={10} ref={ref}>
        <Stack>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} h={"100%"} marginTop={"50px"}>
            <Text fontSize={45} fontWeight={"bold"} fontFamily="DM Sans">Our</Text>

            <Card backgroundColor={"#222222"} textColor={"white"} paddingRight={3} paddingLeft={3} paddingTop={1}
                  paddingBottom={1}>
              <Text fontSize={45} fontWeight={"bold"} fontFamily="DM Sans">pricing</Text>
            </Card>

            <Text fontSize={70} fontWeight={"bold"} fontFamily="DM Sans" textColor={"#FF9800"}>.</Text>

          </Stack>
          <Stack direction={"column"} alignItems={"center"} justifyContent={"space-between"}>
            <Text fontSize={18} fontWeight={"bold"} fontFamily="DM Sans">5 minutes installation - Try it now for
              free</Text>
          </Stack>
        </Stack>

        <Stack direction={"row"} justifyContent={"center"} h={"100%"} marginTop={"100px"}>

          <FreeTier/>

          <PremiumPlan/>

        </Stack>

        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} h={"100%"} marginTop={"30px"}>
          <Text fontSize={32} fontWeight={"bold"} fontFamily="DM Sans">Most of our customers choose it :</Text>

          <Card backgroundColor={"#FF9800"} textColor={"white"} paddingRight={3} paddingLeft={3} paddingTop={1}
                paddingBottom={1}>
            <Text fontSize={32} fontWeight={"bold"} fontFamily="DM Sans">the Premium plan</Text>
          </Card>

          <Text fontSize={50} fontWeight={"bold"} fontFamily="DM Sans" textColor={"#222222"}>.</Text>

        </Stack>

      </Stack>
    )
  }
)

export default Pricing
