import {styled} from "@chakra-ui/react";
import {ReactNode} from "react";

const Page = styled("div", {
  baseStyle: {
    backgroundColor: "#F5F6F8",
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    position: "relative",
  }
})

function PageTemplate({children}: { children: ReactNode }) {

  return (
    <Page>
      {children}
    </Page>
  )
}

export default PageTemplate