import { Button, Stack, Text } from "@chakra-ui/react";
import Header from "../../Components/Global/Header.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import AreaCard from "../../Components/Global/AreaCard.tsx";
import { useMedia } from "react-use";

export type WorkflowCardProps = {
  title: string;
  description?: string;
  buttonText?: string;
};

function WorkflowCard({
  emoji,
  title,
  description,
  buttonText,
  onClick,
}: WorkflowCardProps & { onClick: () => void; emoji: string }) {
  const isWide = useMedia("(min-width: 1000px)");

  return (
    <AreaCard>
      <Header alignEmoji={!isWide} emoji={emoji}>
        <Stack maxW={300}>
          <Text fontSize={24} fontFamily="DM Sans">{title}</Text>
          {isWide && (
            <Text fontSize={14} fontWeight={"light"} color="#A9A9A9" fontFamily="DM Sans">
              {description}
            </Text>
          )}
        </Stack>
      </Header>
      <Stack mt="auto" ml="auto" maxWidth={300}>
        <Button onClick={onClick} fontWeight={"thin"} background={"none"} fontFamily="DM Sans">
          <Stack spacing={20} direction={"row"} alignItems={"center"}>
            {isWide && buttonText && (
              <Text fontSize={14} color="#7E7E7E" fontFamily="DM Sans">
                {buttonText}
              </Text>
            )}
            <FontAwesomeIcon
              fontSize={26}
              color="#BDBDC1"
              icon={faArrowRight}
            />
          </Stack>
        </Button>
      </Stack>
    </AreaCard>
  );
}

export default WorkflowCard;
