import {FaDiscord} from "react-icons/fa";

function ServiceDiscord() {

  return (
    <FaDiscord
      style={{
        width: "35px",
        height: "35px",
        marginRight: "10px",
      }}
    />
  )
}

export default ServiceDiscord