function getServiceColor(index: number, accentIndex = 4) {
  const colors: string[] = ["blue", "red", "yellow", "green", "pink", "orange"];
  const color = colors[index % colors.length];

  let accent = ((accentIndex + 1) % 9) * 100;

  return `${color}.${accent}`;
}

export { getServiceColor };
