import {useAuthContext, AuthProvider} from "./Context/AuthContext.tsx";
import {ChakraProvider} from "@chakra-ui/react";
import Authentication from "./Views/Authentication.tsx";
import {CustomTheme} from "./Components/Global/Theme/ChakraTheme.tsx";
import {
  createBrowserRouter, Route,
  RouterProvider, Routes,
} from "react-router-dom";
import { PageWithNavbar } from "./Components/PageTemplate.tsx";
import Workflows from "./Views/Workflows/index.tsx";
import {useEffect, useState} from "react";
import Profile from "./Views/Profile/Profile.tsx";
import Subscribe from "./Views/Subscribe/Subscribe.tsx";
import Dashboard from "./Views/Dashboard/Dashboard.tsx";
import Workflow from "./Views/Workflows/Edit";
import Landing from "./Views/Landing/Landing.tsx";

export type PathName =
  | "/"
  | "/dashboard"
  | "/dashboard/workflows"
  | "/dashboard/workflows/:workflowId"
  | "/dashboard/profile" | "/dashboard/profile/edit" | "/dashboard/subscribe";

function DashboardRoot() {
  const { isLogged } = useAuthContext();

  const [element, setElement] = useState<JSX.Element>(<Authentication />);

  useEffect(() => {
    if (isLogged) {
      setElement(
        <>
          <Routes>
            <Route path="/" element={<PageWithNavbar/>}>
              <Route path="/" element={<Dashboard/>}/>
              <Route path="/workflows" element={<Workflows/>}/>
              <Route path="/workflows/:workflowId" element={<Workflow/>}/>
              <Route path="/subscribe" element={<Subscribe/>}/>
              <Route path="/profile" element={<Profile/>}/>
            </Route>
          </Routes>
        </>
      );
    } else {
      setElement(<Authentication />);
    }
  }, [isLogged]);

  return <>{element}</>;
}

const appPaths = [
  {
    path: "/",
    children: [
      {
        index: true,
        path: "/",
        element: <Landing />,
      },
      {
        path: "dashboard/*",
        element: <DashboardRoot/>,
      },
    ],
  },
]

const appRouter = createBrowserRouter(appPaths);

function App() {
  return (
    <ChakraProvider theme={CustomTheme}>
      <AuthProvider>
        <RouterProvider router={appRouter}/>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
