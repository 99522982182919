import { ReactNode } from "react";
import { Stack } from "@chakra-ui/react";

function AreaCard({ children, ...props }: { children: ReactNode }) {
  return (
    <Stack {...props} background={"#FAFAFA"} p={5} borderRadius={10}>
      {children}
    </Stack>
  );
}

export default AreaCard;
