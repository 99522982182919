import {useState, useContext, ReactNode, Dispatch, SetStateAction, createContext, useEffect} from 'react';
import Api from "../Services/Api";
import {useToast} from "@chakra-ui/react";
import UserServices from "../Services/User.ts";

type TAuthContext = {
  tokenApp: string;
  setToken: Dispatch<SetStateAction<string>>;
  isLogged: boolean;
  setIsLogged: Dispatch<SetStateAction<boolean>>;
  authenticationMode: string;
  setAuthenticationMode: Dispatch<SetStateAction<string>>;
  authenticationStatus: number;
  setAuthenticationStatus: Dispatch<SetStateAction<number>>;
  handleLogin: (email: string, password: string) => void;
  handleRegister: (nickname: string, email: string, password: string) => boolean;
  handleLogout: () => void;
};

const AuthContext = createContext<TAuthContext>({} as TAuthContext);
const useAuthContext = () => useContext(AuthContext);

function AuthProvider({children}: { children: ReactNode }) {

  const toast = useToast();
  const [tokenApp, setToken] = useState<string>(localStorage.getItem("access_token") || "");
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [authenticationMode, setAuthenticationMode] = useState<string>("login");
  const [authenticationStatus, setAuthenticationStatus] = useState<number>(0);

  useEffect(() => {
    localStorage.setItem("access_token", tokenApp);
  }, [tokenApp]);

  let location = window.location;

  const token = new URLSearchParams(location.search).get("token");

  useEffect(() => {

    if (!isLogged && tokenApp === "") {
      UserServices.getUserAuth(token || "").then(
        () => {
          setToken(token || "");
          setIsLogged(true);
        }
      ).catch(
        (error) => {
          console.log(error);
        }
      )
    } else

    if (!isLogged && tokenApp !== "") {
      setIsLogged(true);
    }
  }, [tokenApp]);

  const handleLogin = (email: string, password: string) => {

    if (email === "" || password === "") {
      toast({
        title: "Error",
        description: "Email or password is empty",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
      return;
    }

    Api.login({email: email, password: password})
      .then((response) => {
        setToken(response.access_token);
        setIsLogged(true);
      })
      .catch((error) => {
        setIsLogged(false);
        console.log(error);
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      })
  }

  const handleRegister = (nickname: string, email: string, password: string) => {
    Api.register({nickname: nickname, email: email, password: password, city: "Paris"})
      .then((response) => {
        setToken(response.access_token);
        setIsLogged(true);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      })
    return true;
  }

  const handleLogout = () => {
    setToken("");
    UserServices.logout().then((res) => {
      console.log(res);
    });
    localStorage.removeItem("access_token");
    setIsLogged(false);
  }

  return (
    <AuthContext.Provider
      value={{
        tokenApp,
        setToken,
        isLogged,
        setIsLogged,
        authenticationMode,
        setAuthenticationMode,
        authenticationStatus,
        setAuthenticationStatus,
        handleLogin,
        handleRegister,
        handleLogout,
      }}>
      {children}
    </AuthContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export {AuthProvider, useAuthContext};
export type {TAuthContext};
