import {Stack} from "@chakra-ui/react";
import SubscribePricing from "../../Components/Subscribe/SubscribePricing.tsx";

function Subscribe() {
  return (
    <Stack alignItems={"center"} justifyContent={"center"} h={"100vh"} w={"100%"}>
      <SubscribePricing/>
    </Stack>
  );
}

export default Subscribe;