import {GrServicePlay} from "react-icons/gr";

function ServiceAny() {

  return (
    <GrServicePlay
      style={{
        width: "35px",
        height: "35px",
        color: "#E69841",
        marginRight: "10px",
      }}
    />
  )
}

export default ServiceAny